import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { BsCamera, BsPen, BsPencil } from "react-icons/bs";
import { MdBadge, MdOutlineBadge } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Bump } from "../components/Bump";
import { Flex } from "../components/Flex";
import { NotificationMethodCard } from "../components/Notifications/NotificationMethodCard";
import { NotificationSetupForm } from "../components/Notifications/NotificationSetupForm";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { SkButton, SkCircleButton } from "../components/SkButton";
import { SkModal } from "../components/SkModal";
import { SkPill } from "../components/SkPill";
import { SkText } from "../components/SkText";
import { SkTextInput } from "../components/SkTextInput";
import { UserImage } from "../components/UserImage";
import { Colors } from "../constants/Colors";
import { NotificationMethod } from "../constants/NotificationMethod";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { SeedTagsAction } from "../data/actions/TagActions";
import { MakeUrl } from "../data/actions/Urls";
import { GetCurrentUserAction, UnsetUserProgress, UpdateCurrentUserAction } from "../data/actions/UserActions";
import { QueryKeys } from "../data/QueryKeys";
import { useAuth } from "../hooks/useAuth";
import { useToggle } from "../hooks/useToggle";
import { useWindowSize } from "../hooks/useWindowSize";
import { Notifications } from "../utils/Notifications";
import { ReactQuery } from "../utils/ReactQuery";
import { FiAtSign } from 'react-icons/fi';

export const ProfileScreen = React.memo(() => {

    const auth = useAuth();
    const linkUpdateModal = useToggle(false)
    const { isMobile } = useWindowSize()

    const notifUpdateModal = useToggle(false)
    const [ notifMethodToUpdate, setNotifMethodToUpdate ] = useState<NotificationMethod>()
    const meta = Notifications.useNotificationMethodStatus(notifMethodToUpdate)
    const handleOpenNotifModal = (method: NotificationMethod) => {
        setNotifMethodToUpdate(method)
        notifUpdateModal.open()
    }

    return (
        <ScreenWrapper>

            <Flex row={ !isMobile } align='stretch'>

                {/* Left Column */}
                <Flex style={{ margin: '60px 0', width: isMobile ? '100%' : 350 }}>
                    <UserImage size={200} onClick={ linkUpdateModal.open } />
                    <SkText variant='section' style={{ marginTop: 20 }}>{auth.user?.name}</SkText>
                    <SkText variant='c1' faded>{auth.user?.username}</SkText>
                    <Bump h={ UiConstants.tightSpacing } />
                    {
                        (auth.user?.role && auth.user?.role !== 'user') ? (
                            <SkPill selected label={ auth.user?.role } style={{ textTransform: 'uppercase' }} textVariant='descriptor'
                                before={ <MdOutlineBadge color='white' style={{ marginRight: UiConstants.tightSpacing }} /> } />
                        ) : null
                    }
                    <Bump h={ UiConstants.standardSpacing } />
                    <SkButton variant='secondary-invert' icon={ <BsPencil color='white' size={ 20 } /> } title='Edit Profile' onClick={ linkUpdateModal.open } />
                </Flex>

                <Bump w={ UiConstants.standardSpacing } />

                {/* Right Column */}
                <Flex style={{ flex: 1 }}>
                    {/* User profile info + controls will go here eventually */}
                    <SkText variant='section'>Notifications</SkText>
                    <Flex row style={{ marginBottom: 40, marginTop: 20, maxWidth: '100%', overflow: 'scroll' }}>
                        <NotificationMethodCard method='telegram' onClick={ () => handleOpenNotifModal('telegram') } />
                        <NotificationMethodCard method='webpush' onClick={ () => handleOpenNotifModal('webpush') } />
                    </Flex>

                    <AdminFeatures />
                    <SkButton variant='primary' style={{ backgroundColor: Colors.FailureRed, marginTop: 80, marginBottom: 80 }} title='Log Out' onClick={ auth.signOut } />

                    <SkModal open={ linkUpdateModal.isOpen } onClose={ linkUpdateModal.close } closerStyle='x' title='Update Profile'>
                        <PfpUpdateForm onDone={ linkUpdateModal.close } />
                    </SkModal>

                    <SkModal open={ notifUpdateModal.isOpen } onClose={ notifUpdateModal.close } closerStyle='x' title={`${meta?.name} Setup`}>
                        <NotificationSetupForm method={ notifMethodToUpdate } onDone={ notifUpdateModal.close } />
                    </SkModal>
                </Flex>

            </Flex>

        </ScreenWrapper>
    )

})

const AdminFeatures = React.memo(() => {

    const auth = useAuth()
    const nav = useNavigate();

    if (auth.user?.role !== 'admin') return null;

    const handleSeedTags = async () => {
        const result = await SeedTagsAction()
        const { created, deleted, total } = result.data || {}
        alert(`Created ${ created } tag(s), deleted ${ deleted } tag(s). Database now contains ${ total } tags.`)
    }

    return (
        <>
            <SkText variant='section' style={{ marginBottom: 20 }}>Admin Features</SkText>
            <Flex row justify='flex-start' style={{ marginTop: 20, flexWrap: 'wrap' }}>
                <SkButton title='View App Stats' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto', background: Colors.Gradient }} onClick={ () => nav(SkRoutes.Admin.AppStats()) } />
                <SkButton title='View Reports' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto' }} onClick={ () => nav(SkRoutes.Admin.Reports()) } />
                <SkButton title='Add An Artist' variant='secondary' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto' }} onClick={ () => nav(SkRoutes.Artist.Create()) } />
                <SkButton title='Seed Tags' variant='secondary' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto' }} onClick={ handleSeedTags } />
                <SkButton title='Reset Welcome Modal' variant='secondary' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto' }} onClick={ () => UnsetUserProgress('welcomed') } />
                <SkButton title='Send Test Web Push' variant='secondary' style={{ padding: '0px 80px', margin: '10px 10px 0 0', width: 'auto' }} onClick={ () => axios.post(MakeUrl('/notifs/wpush/test')) } />
            </Flex>
        </>
    )

})

const PfpUpdateForm = React.memo((props: { onDone?: () => void }) => {

    const { onDone } = props
    const user = useQuery(QueryKeys.User.Current.Profile(), GetCurrentUserAction)
    const [ loaded, setLoaded ] = useState(false);
    const [ pfpImage, setPfpImage ] = useState<string>();
    const [ username, setUsername ] = useState<string>()
    const [ handle, setHandle ] = useState<string>()
    const [ saving, setSaving ] = useState(false)

    useEffect(() => {
        if (loaded) return;
        if (!user?.data?.data?.name) return;
        if (!pfpImage) setPfpImage(user?.data?.data?.imageUrl)
        if (!username) setUsername(user?.data?.data?.name)
        if (!handle) setHandle(user?.data?.data?.username)
        setLoaded(true)
    }, [ user ])

    const handleSubmit = useCallback(async () => {
        if (!/(https:\/\/pbs\.twimg\.com).test(pfpImg)/) alert('Sorry, for security and safety reasons, only Twitter images are currently accepted')
        setSaving(true)
        await UpdateCurrentUserAction({ imageUrl: pfpImage, name: username, handle })
        onDone?.()
        ReactQuery.getClient().invalidateQueries(QueryKeys.User.Current._All())
        setSaving(false)
    }, [ pfpImage, username, handle, onDone ])

    return (
        <>
            <Flex row justify='center' style={{ marginBottom: 40 }}>
                <div style={{ backgroundImage: `url(${pfpImage})`, backgroundSize: 'cover', borderRadius: 100, width: 200, height: 200 }}>
                </div>
            </Flex>
            <Flex row>
                <SkCircleButton><BsCamera color='white' size={ UiConstants.iconButtonSize } /></SkCircleButton>
                <Bump w={ UiConstants.tightSpacing } />
                <SkTextInput value={pfpImage} onTextChange={ setPfpImage } />
            </Flex>
            <Bump h={ UiConstants.standardSpacing } />
            <Flex row>
                <SkCircleButton><MdOutlineBadge color='white' size={ UiConstants.iconButtonSize } /></SkCircleButton>
                <Bump w={ UiConstants.tightSpacing } />
                <SkTextInput value={username} onTextChange={ setUsername } />
            </Flex>
            <Bump h={ UiConstants.standardSpacing } />
            <Flex row>
                <SkCircleButton><FiAtSign color='white' size={ UiConstants.iconButtonSize } /></SkCircleButton>
                <Bump w={ UiConstants.tightSpacing } />
                <SkTextInput value={handle} onTextChange={ setHandle } />
            </Flex>
            <Bump h={ UiConstants.standardSpacing } />
            <SkButton variant='primary' onClick={ handleSubmit } title={ saving ? 'Saving' : 'Save' } disabled={ !pfpImage || user.isLoading || saving } />
        </>
    )

})