import React, { useEffect } from "react";
import { useWindowSize } from "../hooks/useWindowSize";
import { SkCard } from "./Card";
import { Flex } from "./Flex";
import { SkButton, SkCircleButton } from "./SkButton";
import { SkText } from "./SkText";
import { BsX } from 'react-icons/bs'
import { UiConstants } from "../constants/UiConstants";
import { Colors } from "../constants/Colors";
import { useAsyncEffect } from "../hooks/useAsyncEffect";

interface SkModalProps {
    open: boolean
    onClose: () => void
    closeOnBackdropClick?: boolean
    children: JSX.Element[] | JSX.Element
    closerStyle?: 'button' | 'x' | 'none'
    noPadding?: boolean
    title?: string
}

export const SkModal = React.memo((props: SkModalProps) => {

    const { open, onClose, children, closeOnBackdropClick, closerStyle, title, noPadding } = props

    return (
        <SkBackdrop open={ open }>
            <SkCard style={{ maxWidth: '95vw', width: 600, padding: noPadding ? 0 : UiConstants.standardSpacing, backgroundColor: Colors.DarkBackground }}>
                {
                    !title || !closerStyle || closerStyle === 'none' ? null : (
                        <Flex row align='center' justify='space-between' style={{ padding: noPadding ? UiConstants.standardSpacing : 0 }}>
                            { <SkText variant='section'>{ title }</SkText> }
                            { closerStyle === 'x' ? <SkCircleButton onClick={ onClose }><BsX color='white' size={ 30 } /></SkCircleButton> : null }
                        </Flex>
                    )
                }
                <Flex column>
                    <div style={{ margin: '20px 0 20px 0', width: '100%' }}>
                        { children }
                    </div>
                    { closerStyle === 'button' ? <SkButton variant='secondary' onClick={ onClose } title='Cancel' style={{ marginTop: 20 }} /> : null }
                </Flex>
            </SkCard>
        </SkBackdrop>
    )

})

export const SkBackdrop = React.memo((props: { children: JSX.Element, onClick?: () => void, open?: boolean }) => {

    if (!props.open) return null;

    return (
        <Flex justify='center' align='center'
            style={{ position:'fixed', left: 0, top: 0, width: '100vw', height: '100vh', backgroundColor: 'rgba(0,0,0,0.7)', backdropFilter: 'blur(5px)', WebkitBackdropFilter: 'blur(5px)', zIndex: 1000 }}
            onClick={ props.onClick }
            >
                { props.children }
        </Flex>
    )

})