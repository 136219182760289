import React from "react"
import { ArtistProfile } from "../../data/models/Artist"
import { ArtistOnListButton } from "./ArtistOnListButton"

export const ArtistFollowButton = React.memo((props: { artist: ArtistProfile, style?: React.CSSProperties }) => {

    const { artist, style } = props
    return (<ArtistOnListButton artist={ artist } list='followed' labels={{ loading: '...', on: 'Following', off: 'Follow' }} style={ style } />)

})
