import { ArtistSearchParams } from "../data/requests/ArtistSearchParams";
import { QueryString } from "../utils/QueryString";

export const SkRoutes = {

    Home: (artistHandle?: string) => `/${ QueryString.Stringify({ artist: artistHandle }) }`,
    Login: () => '/login',
    Search: (query: string) => `/search${ QueryString.Stringify({ q: query }) }`,
    Browse: (params: { tags?: string[], status?: string[], view?: string, q?: string }) => `/search${ QueryString.Stringify(params) }`,
    Following: (params?: { tags?: string[], status?: string[] }) => `/following${QueryString.Stringify(params || {})}`,
    Profile: () => `/profile`,
    Feed: {
        Post: (artistId: string, postId: string) => `/artist/${ artistId }/post/${ postId }`
    },
    Admin: {
        AppStats: () => `/admin/stats`,
        Reports: () => `/admin/reports`
    },
    Artist: {
        Show: (artistHandle: string, sourceList?: ArtistSearchParams) => `/artist/${artistHandle}?${ sourceList ? `c=${ QueryString.Object.Pack(sourceList) }` : '' }`,
        Edit: (artistHandle: string) => `/artist/${artistHandle}/edit`,
        Create: () => `/artist/create`,
    }

}