import { format, formatDistanceStrict, formatDistanceToNow, formatDistanceToNowStrict, isSameDay, isThisYear, isToday, isYesterday } from "date-fns"

type DateInput = Date | number | string

const ms24hr = 1000 * 60 * 60 * 24
const ms7days = ms24hr * 7

const SafeFormat = (d: Date, template: string) => {
    try { return format(d, template) }
    catch (e) { return '--- ' }
}

const replacements = [
    { find: / seconds?/, replace: 's' },
    { find: / minutes?/, replace: 'm' },
    { find: / hours?/, replace: 'h' },
    { find: / days?/, replace: 'd' },
    { find: / weeks?/, replace: 'w' },
    { find: / months?/, replace: 'mo' },
    { find: / years?/, replace: 'y' }
]

export const FormatDate = {

    dateOnly: (src: DateInput) => SafeFormat(new Date(src), 'dd MMM yyyy'),

    adaptiveTime: (src: DateInput) => {
        const d = new Date(src)
        if (isToday(d)) return SafeFormat(d, 'h:mm aa')
        if (isYesterday(d)) return `Yesterday, ${SafeFormat(d, 'h:mm aa')}`

        const tDiff = (new Date()).getTime() - d.getTime()
        if (tDiff <= ms7days) return SafeFormat(d, 'cccc, h:mm aa') // Monday, Tuesday, etc.
        if (isThisYear(d)) return SafeFormat(d, 'dd MMM')
        else return SafeFormat(d, 'dd MMM yyyy')
    },

    toNow: (src: DateInput) => {
        try { return formatDistanceToNow(new Date(src), { addSuffix: true }) }
        catch (e) { return '---' }
    },

    toNowAbbrev: (src: DateInput) => {
        const toNow = formatDistanceToNowStrict(new Date(src))
        try {
            const r = replacements.find(r => r.find.test(toNow))
            return toNow.replace(r.find, r.replace);
        } catch (e) {
            console.log({ _me: 'toNowAbbrev failure', toNow, src, error: e })
            return '---'
        }
    },

}