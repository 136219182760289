import React, { useMemo } from "react";
import { BsTwitter } from "react-icons/bs";
import { CgMoreAlt } from "react-icons/cg";
import { Flex } from "../../components/Flex";
import { SkCircleButton } from "../../components/SkButton";
import { BskyUrl, TwitterUrl } from "../../data/actions/Urls";
import { ArtistProfile } from "../../data/models/Artist";
import { useWindowSize } from "../../hooks/useWindowSize";
import { ArtistFollowButton } from "./ArtistFollowButton";
import { ArtistSubscribeButton } from "./ArtistSubscribeButton";
import { FaCloud } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

export const ArtistButtonsAndTags = React.memo((props: { artist: ArtistProfile, onMore: () => void }) => {

    const { artist, onMore } = props
    const { scale } = useWindowSize()
    const iconSize = 26 * scale

    return (
        <div style={{ width: '100%' }}>
            <Flex row style={{ width: '100%' }}>
                <ArtistFollowButton artist={ artist } style={{ flex: 1, marginRight: 10, maxWidth: 200 }} />
                <ArtistSubscribeButton artist={ artist } />
                <ArtistMainSocialButton artist={ artist } />
                <SkCircleButton onClick={ onMore }><CgMoreAlt color='white' size={ iconSize } /></SkCircleButton>
            </Flex>
        </div>
    )

})

const ArtistMainSocialButton = React.memo((props: { artist: ArtistProfile }) => {

    const { scale } = useWindowSize()
    const iconSize = 26 * scale

    const { artist } = props
    const { href, icon, disabled } = useMemo(() => {
        if (!artist) return { href: '', icon: null, disabled: true }
        else if (artist.bskyHandle) return { href: BskyUrl.ForUser(artist?.bskyHandle), icon: <FaCloud color='white' size={ iconSize } />, disabled: false }
        else if (artist.twitter) return { href: TwitterUrl.ForUser(artist?.twitter), icon: <FaXTwitter color='white' size={ iconSize } />, disabled: false }
        else return { href: `javascript:alert('${artist.handle} has no Bsky / X profile')`, icon: <FaCloud color='white' size={ iconSize } style={{ opacity: 0.5 }} />, disabled: true }
    }, [artist])

    return (
        <SkCircleButton href={ href } style={{ margin: '0 10px' }} disabled={ disabled }>
            { icon }
        </SkCircleButton>
    )

})