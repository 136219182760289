import React, { useEffect, useMemo, useState } from "react";
import { NotificationMethod } from "../../constants/NotificationMethod";
import { useAuth } from "../../hooks/useAuth";
import { Flex } from "../Flex";
import { SkText } from "../SkText";
import { NotificationOptionsMenu } from "./NotificationOptionsMenu";
import { TelegramSetupPage } from "./TelegramSetupPage";
import { WebPushSetupPage } from "./WebPushSetupPage";

type NotificationSetupPhase = 'loading' | 'options-menu' | 'setup-time'
export const NotificationSetupForm = React.memo((props: { method: NotificationMethod, onDone: () => void }) => {

    const [ phase, setPhase ] = useState<NotificationSetupPhase>('loading')
    const { method, onDone } = props
    const { user } = useAuth()
    const profile = user.notifications?.[method]

    useEffect(() => {
        const s = profile?.status
        if (phase === 'setup-time') return // Don't steal the user away from our sweet lucious success screen
        if (s === 'active' || s === 'paused') setPhase('options-menu')
        else setPhase('setup-time')
    }, [ profile ])

    const content = useMemo(() => {

        if (phase === 'options-menu') return <NotificationOptionsMenu method={ method } onClose={ onDone } />
        else if (phase === 'loading') return <SkText variant='c1'>Loading...</SkText>
        else {
            switch(method) {
                case 'telegram': return <TelegramSetupPage onDone={ onDone } />
                case 'webpush': return <WebPushSetupPage onDone={ onDone } />
                default: return <SkText variant='c1'>Oh no! Wrong turn, sorry about that! Please let us know how you got here so we can patch things up.</SkText>
            }
        }

    }, [ phase ])

    return (
        <Flex style={{ height: 500 }}>
            { content }
        </Flex>
    )

})