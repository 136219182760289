import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ConfigureAuth, Startup } from "../Startup";
import { Flex } from "../components/Flex";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import { Logo } from "../components/Logo";
import { SkImageTheater2 } from "../components/SkImageTheater2";
import { Colors } from "../constants/Colors";
import { useAsyncEffect } from "../hooks/useAsyncEffect";
import { useAuth } from "../hooks/useAuth";
import { AppStatsScreen } from "./Admin/AppStatsScreen";
import { ReportsScreen } from "./Admin/ReportsScreen";
import { ArtistScreen } from "./ArtistScreen/ArtistScreen";
import { CreateArtistScreen, EditArtistScreen } from "./EditArtistScreen";
import { FollowedScreen } from "./FollowedScreen";
import { HomeScreen } from "./HomeScreen";
import { NewBrowseScreen } from "./NewBrowseScreen/NewBrowseScreen";
import { PostScreen } from "./PostScreen";
import { ProfileScreen } from "./ProfileScreen";
import { SplashScreen } from "./SplashScreen";

let lastScrollY = window.scrollY;
let scrollDirection = 'down';
let startScrollY = window.scrollY;
let startHeaderTop = 0;
let headerTop = 0;
let scrollChange = 0;

export const BaseScreen = React.memo(() => {

    const [ ready, setReady ] = useState(false)
    const [ authenticated, setAuthenticated ] = useState(false);
    const auth = useAuth();

    useAsyncEffect(async () => {
        if(auth.state === 'ok' && ready === false) {
            setAuthenticated(true);
            ConfigureAuth(auth.getToken);
            await Startup();
            setReady(true);
        }
        else if(auth.state === 'needs-login') {
            await auth.redirectToLogin();
        }
    }, [ auth.state, ready ]);

    const handleScroll = useCallback((e: Event) => {
        const windowScroll = Math.max(window.scrollY, 0);

        if(scrollDirection === 'down' && scrollChange > 50) {
            document.body.classList.add('scrolled');
        }
        else if(scrollDirection === 'up' && (scrollChange > 50 || windowScroll === 0)) {
            document.body.classList.remove('scrolled');
        }

        if(windowScroll > lastScrollY) {
            if(scrollDirection === 'down') {
                scrollChange = windowScroll - startScrollY;
            }
            else {
                startScrollY = windowScroll;
                scrollDirection = 'down';
                scrollChange = 0;
            }
        }
        else {
            if(scrollDirection === 'up') {
                scrollChange = startScrollY - windowScroll;
            }
            else {
                startScrollY = windowScroll;
                scrollDirection = 'up';
                scrollChange = 0;
            }
        }

        lastScrollY = windowScroll;
        //document.getElementById('header')?.setAttribute('style', `top: ${headerTop}px`);
    }, []);
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    // useEffect(() => {
    //     // New users: we need to wait for the API to have new users initailize
    //     if (auth?.userId && auth?.user) {
    //         setAuthenticated(true);
    //         ConfigureAuth(auth.getToken);
    //     }
    // }, [ auth.userId, auth.user ])

    // useAsyncEffect(async () => {
    //     await Startup()
    //     setReady(true);
    // }, []);

    // If we're still bootstrapping the app, or waiting for the user's data to come back
    // then display the splash
    if (!ready || (auth.state === 'not-ready')) return <SplashScreen />

    return (
        // <Flex style={{ background: auth.loggedIn ? undefined : Colors.Gradient }}>
        <>
            <Header />
            <LoginLogo />
            <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/profile" element={<ProfileScreen />} />
                <Route path="/following" element={<FollowedScreen />} />
                <Route path="/search" element={<NewBrowseScreen />} />
                <Route path="/artist/:artistId" element={<ArtistScreen />} />
                <Route path="/artist/:artistId/edit" element={<EditArtistScreen />} />
                <Route path="/artist/create" element={<CreateArtistScreen />} />
                <Route path="/artist/:artistId/post/:postId" element={<PostScreen />} />
                <Route path="/admin/stats" element={<AppStatsScreen />} />
                <Route path="/admin/reports" element={<ReportsScreen />} />
            </Routes>
            <SkImageTheater2 />
            <Footer />
        </>
        // </Flex>
    );

})

const LoginLogo = React.memo(() => {

    const { loggedIn } = useAuth()
    const [ loginFormExists, setLoginFormExists ] = useState(false)
    useEffect(() => {
        setTimeout(() => {
            if (!loggedIn) setLoginFormExists(!!document.querySelector('#supertokens-root'))
        }, 100)
    }, [ loggedIn ])

    const show = loginFormExists && !loggedIn
    if (!show) return null

    return (
        <Flex row style={{ position: 'absolute', width: '100vw', top: 30 }} justify='center'>
            <Flex align='center'>
                <Logo variant='word' size={ 60 } />
            </Flex>
        </Flex>
    )

})