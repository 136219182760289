import algoliasearch from 'algoliasearch'
import axios from 'axios'
import { setConfiguration as ConfigureGrids } from 'react-grid-system'
import { InitializeTags } from './constants/RelevantTags'
import { UiConstants } from './constants/UiConstants'
import { DebouncedAlgoliaRequester } from './helpers/DebouncedAlgoliaRequester'
import { useConfig } from './stores/ConfigStore'
import { useAuth } from './hooks/useAuth'

let algoliaTimeout: NodeJS.Timeout;

export const Startup = async () => {

    // Configure Axios
    axios.interceptors.request.use((config) => {
        config.withCredentials = true
        return config;
    })

    // Configure Algolia
    useConfig.getState().setConfig({
        algolia: {
            searchClient: algoliasearch('CIHDQUFUF1', '39a4ae2028190a5925633743b37ff775', {
                requester: DebouncedAlgoliaRequester,
                timeouts: { connect: 1000, read: 1000, write: 1000 }
            }),
            indexName: 'default'
        }
    })

    // Configure Grids
    ConfigureGrids({ gutterWidth: UiConstants.tightSpacing })

    // Download All Tags
    await InitializeTags()

}

export const ConfigureAuth = async (tokenProvider: () => Promise<string>) => {
    axios.interceptors.request.use(async (config) => {
        config.headers['Authorization'] = `Bearer ${await tokenProvider()}`;
        return config;
    });
}