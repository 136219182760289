import React, { useEffect, useMemo, useState } from "react"
import { IconBaseProps } from "react-icons"
import { BiVolumeMute } from "react-icons/bi"
import { MdOutlineNotificationsActive, MdOutlineNotificationAdd } from "react-icons/md"
import { RiUserFollowLine, RiUserAddLine } from "react-icons/ri"
import { SkButton, SkButtonVariant, SkCircleButton } from "../../components/SkButton"
import { Colors } from "../../constants/Colors"
import { UiConstants } from "../../constants/UiConstants"
import { ArtistProfile, ArtistSearchResult } from "../../data/models/Artist"
import { ArtistIsType, useArtistIs } from "../../hooks/useArtistIs"

interface ArtistOnListButtonProps {
    artist: ArtistSearchResult,
    list: ArtistIsType,
    style?: React.CSSProperties,
    onChange?: (onList: boolean) => void,
    labels?: { loading: string, on: string, off: string }
    circle?: boolean
    circleSize?: number
    variant?: SkButtonVariant
    children?: any
}


export const ArtistOnListButton = React.memo((props: ArtistOnListButtonProps) => {

    const { artist, list, style, labels, circle, circleSize, children, onChange, variant } = props

    const { onList, add, remove, saving } = useArtistIs(list, artist?.handle)

    useEffect(() => onChange?.(onList), [ onList ])

    const handleClick = async () => {
        if (onList === undefined || saving) return;
        if (onList) remove()
        else add()
    }

    const state = useMemo(() => {
        switch(onList) {
            case undefined: return 'loading'
            case true: return 'on'
            case false: return 'off'
        }
    }, [ onList ])

    const opacity = useMemo(() => {
        if (onList === undefined) return 0
        return saving ? 0.4 : 1
    }, [ saving, onList ])

    if (circle) return (<SkCircleButton onClick={ handleClick } size={ circleSize } variant={ onList ? 'primary' : 'secondary-invert' } style={{ opacity, ...style }}>{ children }</SkCircleButton>)

    return (<SkButton title={ labels[state] } variant={ variant } style={{ opacity, ...style }} onClick={ handleClick } />)


})

// "IconProps"
const ip: IconBaseProps = {
    color: 'white',
    size: UiConstants.iconButtonSize * 0.7
}

export const ArtistOnLineCircleButtonManaged = React.memo((props: { list?: ArtistIsType, artist: ArtistSearchResult }) => {

    const { list, artist } = props
    const [ onList, setOnList ] = useState(false)
    const buttonIcon = useMemo(() => {
        switch (list) { 
            case 'followed': return onList ? <RiUserFollowLine { ...ip } /> : <RiUserAddLine { ...ip } />
            case 'subscribed': return onList ? <MdOutlineNotificationsActive { ...ip } /> : <MdOutlineNotificationAdd { ...ip } />
            case 'muted': return <BiVolumeMute { ...ip } />
            default: return null
        }
    }, [ list, onList ])

    const buttonColor = useMemo(() => {
        if (!onList) return undefined
        switch (list) {
            case 'followed': return Colors.PrimaryBlue;
            case 'subscribed': return Colors.BeautifulMagenta;
            case 'muted': return Colors.FailureRed;
        }
    }, [ list, onList ])

    if (!list || !artist) return null;

    return (
        <ArtistOnListButton list={ list } circle circleSize={ 45 } style={{ backgroundColor: buttonColor, opacity: onList ? 1 : 0.4 }} artist={ artist } onChange={ setOnList }>
            { buttonIcon }
        </ArtistOnListButton>
    )

})