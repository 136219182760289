import { useQuery } from "@tanstack/react-query"
import Axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SkRoutes } from "../constants/Routes"
import { GetTokenAction } from "../data/actions/AuthenticationActions"
import { TokenMemo } from "../data/actions/TokenMemo"
import { MakeUrl } from "../data/actions/Urls"
import { GetCurrentUserAction } from "../data/actions/UserActions"
import { CurrentUser } from "../data/models/CurrentUser"
import { SkResponse } from "../data/models/SkResponse"
import { Token } from "../data/models/Token"
import { QueryKeys } from "../data/QueryKeys"
import { useClerk, useAuth as clerkUseAuth } from "@clerk/clerk-react";

export type AuthState = 'not-ready' | 'ok' | 'needs-login'
export type SkAuth = {
    state: AuthState
    userId?: string
    loggedIn?: boolean
    user?: CurrentUser
    tokenPayload?: any
    loading: boolean
    signOut: () => Promise<void>
    redirectToLogin: () => Promise<void>
    getToken?: () => Promise<string>
}

export const useAuth = (provider?: 'fusionauth' | 'debug' | 'clerk'): SkAuth => {
    switch (provider) {
        case 'debug': return GetDebugAuthProvider();
        case 'fusionauth': return GetFusionAuthProvier();
        case 'clerk':
        default:
            return GetClerkAuthProvider();
    }
}

const GetDebugAuthProvider = (): SkAuth => {
    const currentUser = useQuery<SkResponse<CurrentUser>>(QueryKeys.User.Current.Profile(), GetCurrentUserAction);

    return {
        state: 'ok',
        tokenPayload: {},
        loggedIn: !!currentUser?.data?.data,
        userId: TokenMemo.Get(),
        redirectToLogin: async () => {},
        signOut: async () => {},
        loading: currentUser?.isLoading,
        user: currentUser?.data?.data
    }
}

const GetClerkAuthProvider = (): SkAuth => {
    const { user: clerkUser, loaded, redirectToSignIn, signOut } = useClerk();
    const { getToken } = clerkUseAuth();
    const user = useQuery<SkResponse<CurrentUser>>(QueryKeys.User.Current.Profile(), async () => {
        const token = await getToken();
        return Axios.get(MakeUrl('/users/me'), { headers: { Authorization: `Bearer ${token}` } })
    }, { enabled: loaded });

    let state: AuthState = 'not-ready';
    if(loaded && clerkUser)
        state = 'ok';
    else if(loaded && !clerkUser)
        state = 'needs-login';
    else if(!loaded)
        state = 'not-ready';
        
    return {
        state,
        loading: !loaded,
        userId: clerkUser?.id,
        loggedIn: state === 'ok',
        redirectToLogin: async () => { redirectToSignIn() },
        signOut: signOut,
        tokenPayload: null,
        getToken: getToken,
        user: user?.data?.data
    }
}

const GetFusionAuthProvier = (): SkAuth => {

    const [ state, setState ] = useState<AuthState>('not-ready')
    const user = useQuery<SkResponse<CurrentUser>>(QueryKeys.User.Current.Profile(), GetCurrentUserAction, { staleTime: 1000 * 10 });
    const token = useQuery<SkResponse<Token>>(QueryKeys.Auth.Token(), GetTokenAction, { staleTime: 1000 * 10 });
    const loading = user?.isLoading || token?.isLoading;

    useEffect(() => {

        // Notice: this is a very delicate effect, I did all kinds of annoying research
        // .isLoading gets set to true on user and token even when their data isn't
        // actually ready or w/e. Instead, we have go on the HTTP code for the token:
        //
        //    * HTTP 200    -> All good
        //    * HTTP 4XX    -> Needs login
        //    * [undefined] -> Still loading
        // 
        // Also, don't say we're ready until the user's data has loaded in-- it breaks the app otherwise

        const tokenStatus = token.data?.status
        const userLoaded = !!user.data?.data

        if (!tokenStatus) return;
        else if (tokenStatus >= 400) setState('needs-login')
        else if (userLoaded) setState('ok')

    }, [ token?.data?.status, user?.data?.data ])

    useEffect(() => {
        console.log(`TOKEN HTTP STATUS = ${ token?.data?.status }`)
    }, [ token?.data?.status ])

    return {
        state,
        loading: state === 'not-ready',
        userId: user?.data?.data?.id,
        loggedIn: state === 'ok',
        redirectToLogin: async () => {
            window.location.href = MakeUrl('/auth/login')
        },
        signOut: async () => { window.location.href = MakeUrl('/auth/logout') },
        tokenPayload: null,
        user: user?.data?.data
    }
}
