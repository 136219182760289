import React from "react";
import { NotificationMethod } from "../../constants/NotificationMethod";
import { RemoveWebPushSubscriptionAction } from "../../data/actions/NotificationActions";
import { QueryKeys } from "../../data/QueryKeys";
import { useWindowSize } from "../../hooks/useWindowSize";
import { Notifications } from "../../utils/Notifications";
import { ReactQuery } from "../../utils/ReactQuery";
import { Bump } from "../Bump";
import { Flex } from "../Flex";
import { SkButton } from "../SkButton";
import { SkText } from "../SkText";
import { NotificationMethodCard } from "./NotificationMethodCard";

export const NotificationOptionsMenu = React.memo((props: { method: NotificationMethod, onClose?: () => void }) => {

    const { method, onClose } = props
    const { isMobile } = useWindowSize()
    const meta = Notifications.useNotificationMethodStatus(method)

    const handleRemove = async () => {
        await meta.provider.unsubscribe()
        ReactQuery.getClient().invalidateQueries(QueryKeys.User.Current.Profile())
        onClose?.()
    }

    return (
        <Flex style={{ flex: 1 }}>

            <Flex row={ !isMobile } style={{ flex: 1 }}>
                <NotificationMethodCard method={ method } />
                <Flex style={{ marginTop: isMobile ? 20 : 0 }}>
                    <SkText variant='c1'>{ meta.name } notifications are currently { meta.statusText }</SkText>
                    <Bump h={ 20 } />
                    { meta.status === 'different-device' ? (
                        <SkText variant='c2' faded>Registration across multiple accounts / devices is not currently supported. To change { meta.name } notifications to this device, press the remove button below to remove your configuration first and then restart { meta.name } setup.</SkText>
                    ) : null }
                </Flex>
            </Flex>

            <SkButton variant='primary' style={{ margin: '20px 0' }} title={`Pause ${ meta.name } Notifications`} onClick={ () => alert('Pausing alerts is not currently support, but coming soon!') } />
            <SkButton variant='secondary' title={`Remove My ${ meta.name } Settings`} onClick={ handleRemove } />

        </Flex>
    )

})