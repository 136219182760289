import React from "react";
import { FeedItemDisplay } from "../../components/Feed";
import { Flex } from "../../components/Flex";
import { MobileSearchArea } from "../../components/Search/MobileSearchArea";
import { useHeaderSearchUiContext } from "../../components/Search/useSearchUiContext";
import { SkTabs } from "../../components/SkTabs";
import { TagPicker } from "../../components/TagPicker";
import { ArtistCarouselGrid } from "../BrowseScreen";
import { BrowseStatusOptions, useSearchParams } from "./NewBrowseScreen";
import { useBrowseScreenSearchContext } from "./useBrowseScreenSearchContext";
import { useSkImageTheater } from "../../components/SkImageTheater2";

export const NewBrowseScreenMobile = React.memo(() => {

    const headerSearch = useHeaderSearchUiContext()

    return (
        <Flex>
            <MobileSearchArea onDisplayingResults={ headerSearch.setResultVisibility } />
            { headerSearch.resultsVisible ? null : <ExploreContent /> }
        </Flex>
    )
})

const ExploreContent = React.memo(() => {

    const [params, setParams] = useSearchParams()
    const { artistResults, postResults } = useBrowseScreenSearchContext()
    const openTheater = useSkImageTheater(s => s.open)

    return (
        <Flex column style={{ maxWidth: '100vw', minWidth: '100vw' }}>

            <TagPicker selected={ params.tags } onSelect={ tags => setParams({ tags }) } scroll style={{ maxWidth: '100%' }} />

            <SkTabs
                variant='horizontal'
                options={BrowseStatusOptions}
                value={params.status?.[0] || 'all'}
                onSelect={v => setParams({ status: [v] })}
            />

            <ArtistCarouselGrid artists={ artistResults } onViewAll={ () => {} } />

            <Flex column align='stretch'>
                {postResults?.map(p => <FeedItemDisplay item={p} onImageClick={idx => openTheater(p.media, idx)} />)}
            </Flex>

        </Flex>
    )
})
