import React, { useMemo } from "react";
import { BsCheckCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Colors } from "../constants/Colors";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { ArtistProfile, ArtistSearchResult } from "../data/models/Artist";
import { ArtistSearchParams } from "../data/requests/ArtistSearchParams";
import { useWindowSize } from "../hooks/useWindowSize";
import { FormatDate } from "../utils/FormatDate";
import { ArtistPlanet } from "./ArtistPlanet";
import { Flex } from "./Flex";
import { SkText, SkTextVariant } from "./SkText";

interface ArtistPreviewCardProps {
    artist:  ArtistSearchResult & Partial<ArtistProfile>
    onClick?: () => void
    navOnClick?: boolean
    searchContext?: ArtistSearchParams
    checked?: boolean
    showTimeSinceOpen?: boolean
    size?: 'normal' | 'small' | 'tall' | 'fixed-width'
    style?: React.CSSProperties
    noMargin?: boolean
}

const heightRatio = 200 / 270;
export const ArtistPreviewCard = React.memo((props: ArtistPreviewCardProps) => {

    const { artist, style, onClick, navOnClick, checked, size, noMargin, showTimeSinceOpen, searchContext } = props
    const nav = useNavigate()

    const handleClick = () => {
        if (navOnClick) nav(SkRoutes.Artist.Show(artist.handle, searchContext))
        onClick?.()
    }

    const { isMobile } = useWindowSize()

    const { height, width, titleVariant } = useMemo(() => {
        switch (size) {
            case 'small': return { height: heightRatio * 180, width: '100%', titleVariant: 'name' }
            case 'tall': return { height: (1 / heightRatio) * 180, width: '100%', titleVariant: 'name' }
            case 'fixed-width': return { height: heightRatio * 270, width: 270, titleVariant: 'name' }
            default: return { height: heightRatio * 270, width: '100%', titleVariant: 'name' }
        }
    }, [ size, isMobile ])

    return (
        <Flex className="artist-preview" justify='flex-end' style={{
            background: checked ? Colors.SuccessGreen : Colors.Gradient, cursor: (onClick || navOnClick) ? 'pointer' : undefined,
            height, minWidth: width, maxWidth: width, marginRight: noMargin ? undefined : 10, borderRadius: UiConstants.borderRadius, ...style
        }}
            onClick={ handleClick }>
            { (showTimeSinceOpen && artist.commissionStatusDate) ? <SkText variant='descriptor' style={{
                margin: UiConstants.tightSpacing, padding: 5, width: 20, height: 20, fontSize: 12, display: 'flex',
                justifyContent: 'center', alignItems: 'center',
                backgroundColor: Colors.DarkBackground, borderRadius: UiConstants.borderRadius
            }}>{ FormatDate.toNowAbbrev(artist.commissionStatusDate) }</SkText> : null }
            { checked ? (
                <Flex justify='center' align='center' style={{ width: '100%', flex: 1 }}>
                    <BsCheckCircle color='white' size={ 80 } />
                </Flex>
            )
            : null }
            <div style={{
                backgroundColor: Colors.DarkerBackground, padding: 10, width: '100%', height: 80, boxSizing: 'border-box',
                borderBottomLeftRadius: UiConstants.borderRadius - 4, borderBottomRightRadius: UiConstants.borderRadius - 4
            }}>
                <Flex row>

                    <ArtistPlanet artist={ artist } size={ 30 } moonHaloColor={ Colors.DarkerBackground } />

                    <Flex style={{ marginLeft: 20 }}>

                        <Flex row justify='space-between'>
                            <SkText variant={ titleVariant as SkTextVariant }>{ artist.handle }</SkText>
                        </Flex>

                        <SkText variant='descriptor' faded>{ artist.tags?.join(' / ') }</SkText>

                    </Flex>

                </Flex>
            </div>
        </Flex>
    )


})