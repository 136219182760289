import React, { useCallback, useEffect, useRef, useState } from "react";
import { CgChevronLeft } from "react-icons/cg";
import { MdSearch } from "react-icons/md";
import { InstantSearch, useSearchBox } from "react-instantsearch-core";
import { useNavigate } from "react-router-dom";
import { SkRoutes } from "../../constants/Routes";
import { UiConstants } from "../../constants/UiConstants";
import { useConfig } from "../../stores/ConfigStore";
import { Flex } from "../Flex";
import { SkCircleButton } from "../SkButton";
import { SkTextInput } from "../SkTextInput";
import { SearchHitContainer } from "./SearchHitContainer";
import { useHeaderSearchUiContext } from "./useSearchUiContext";
import { ConfigureSearchForTypes } from "./ConfigureSearchForTypes";
import { useBrowseScreenSearchContext } from "../../screens/NewBrowseScreen/useBrowseScreenSearchContext";


export const MobileSearchArea = React.memo((props: {
    onDisplayingResults: (visible: boolean) => void
}) => {

    const { onDisplayingResults } = props
    const { searchClient, indexName } = useConfig(c => c.algolia)

    const { resultsVisible, hideResults } = useHeaderSearchUiContext()
    const displayingResults = resultsVisible

    useEffect(() => {
        onDisplayingResults(displayingResults)
    }, [ onDisplayingResults, displayingResults ])

    return (
        <InstantSearch searchClient={ searchClient } indexName={ indexName }>
            <ConfigureSearchForTypes types={['artist', 'tag']} />
            <Flex row style={{ margin: UiConstants.screenInset, marginTop: 5 }}>
                <SkCircleButton onClick={ hideResults } style={{ marginRight: 10 }}>
                    { displayingResults ? <CgChevronLeft color='white' size={ 20 } /> : <MdSearch color='white' size={ 20 } />  }
                </SkCircleButton>
                <MobileSearchBox />
            </Flex>
            <SearchHitContainer />
        </InstantSearch>
    )

})

const MobileSearchBox = React.memo((props: {
}) => {

    const { refine } = useSearchBox()
    const { showResults, query, setQuery } = useHeaderSearchUiContext()
    const inputRef = useRef<HTMLInputElement>()
    const nav = useNavigate()

    const handleChange = useCallback((newQuery: string) => {
        setQuery(newQuery)
        refine(newQuery)
    }, [ refine, setQuery ])

    return (
        <SkTextInput
            ref={ inputRef }
            showReset
            value={ query }
            onReset={ () => { inputRef?.current?.focus(); useBrowseScreenSearchContext.getState().clearQueryFromRoute(nav); } }
            onTextChange={ handleChange }
            onFocus={ showResults }
        />
    )
})
