import { useMemo, useState } from "react"
import { Colors } from "../constants/Colors"
import { INotificationProvider, NotificationMethod, TelegramMetadataProvider, WebPushMetadataProvider } from "../constants/NotificationMethod"
import { useAsyncEffect } from "../hooks/useAsyncEffect"
import { useAuth } from "../hooks/useAuth"

export type NotificationMethodStatus = 'loading' | 'active' | 'paused' | 'not-configured' | 'different-device'
const useNotificationMethodStatus = (method: NotificationMethod) => {

    const { user } = useAuth()
    const [ status, setStatus ] = useState<NotificationMethodStatus>('loading')
    const profile = useMemo(() => user?.notifications?.[method], [ method, user ])

    const statusMeta = useMemo(() => {
        switch (status) {
            case 'loading': return { text: 'loading...', sublabel: '...', color: Colors.InactiveGray }
            case 'active': return { text: 'enabled', sublabel: 'Enabled', color: Colors.PrimaryBlue }
            case 'paused': return { text: 'paused', sublabel: 'Paused', color: Colors.InactiveGray }
            case 'different-device': return { text: 'set up on a different device', sublabel: 'Other Device', color: Colors.BeautifulMagenta }
            case 'not-configured': return { text: 'not set up', sublabel: 'Set Up', color: Colors.InactiveGray }
            default: return { text: 'in an unknown state', sublabel: '...', color: Colors.InactiveGray }
        }
    }, [ status ])

    const provider = useMemo(() => {
        switch (method) { 
            case 'telegram': return TelegramMetadataProvider
            case 'webpush': return WebPushMetadataProvider
            default: return null
        }
    }, [ method ])

    useAsyncEffect(async () => {
        if (!provider) return;
        setStatus('loading')
        const s = await provider.getStatus(profile)
        setStatus(s)
    }, [ provider, method, profile ])

    return {
        name: provider?.name,
        status: status,
        statusText: statusMeta.text,
        statusColor: statusMeta.color,
        sublabel: statusMeta.sublabel,
        flavorVerb: provider?.flavorVerb,
        icon: provider?.icon(75, 'white'),
        profile,
        provider: provider as INotificationProvider
    }

}

let _sub: PushSubscription
const getWebPushSubscription = async (): Promise<PushSubscription> => {
    if (_sub) return _sub
    const reg = await navigator.serviceWorker.getRegistration();
    const sub = await reg?.pushManager?.getSubscription()
    _sub = sub
    return sub;
}

const subscribeToWebPush = async (): Promise<PushSubscription> => {
    const reg = await navigator.serviceWorker.getRegistration();
    return reg?.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: 'BPkNUoaaTtpaMU1wL9z0pPbkb6HJZv-KUryu70J08ADRsVMQ4JcxpRZ2LEMgX4KNDArB6HDnoZfkJ154keLllbg'
    })
}

const unsubscribeFromWebPush = async (): Promise<PushSubscription> => {
    const sub = await getWebPushSubscription();
    if (!sub) return; // No subscription to speak of
    sub.unsubscribe();
    return sub
}

export const Notifications = {
    useNotificationMethodStatus,
    WebPush: {
        subscribe: subscribeToWebPush,
        unsubscribe: unsubscribeFromWebPush,
        getSubscription: getWebPushSubscription
    }
}