import React, { useEffect } from "react";
import { NotificationMethod } from "../../constants/NotificationMethod";
import { UiConstants } from "../../constants/UiConstants";
import { Notifications } from "../../utils/Notifications";
import { Flex } from "../Flex";
import { SkText } from "../SkText";

export const NotificationMethodCard = React.memo((props: { method: NotificationMethod, onClick?: () => void, noPadding?: boolean }) => {

    const meta = Notifications.useNotificationMethodStatus(props.method);
    useEffect(() => {

    }, [ meta ])

    return (
        <Flex align='center' style={{
                backgroundColor: meta.statusColor, borderRadius: UiConstants.borderRadius, minWidth: 140, maxWidth: 140, height: 180, marginRight: props.noPadding ? 0 : 20,
                padding: UiConstants.standardSpacing, cursor: props.onClick ? 'pointer' : undefined
            }}
            onClick={ props.onClick }
            >
            <Flex justify='center' style={{ flex: 1 }}>
                { meta.icon }
            </Flex>
            <SkText variant='c1' style={{ fontWeight: 'bold', marginBottom: UiConstants.tightSpacing }}>{ meta.name }</SkText>
            <SkText variant='descriptor' style={{ fontWeight: 'bold' }}>{ meta.sublabel }</SkText>
        </Flex>
    )

})