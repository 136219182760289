import { useState } from "react"
import { FeedItem } from "../data/models/FeedItem"
import { ToggleModel, useToggle } from "./useToggle"

export type SelectedFeedItemContext = {
    item: FeedItem,
    selectedIndex: number
}

export const useSelectedFeedItem = (): {
    context: SelectedFeedItemContext,
    set :(item: FeedItem, index: number) => void,
    open :(item: FeedItem, index: number) => void,
    toggle: ToggleModel
} => {

    const showTheater = useToggle(false)
    const [ selectedItem, setSelectedItem ] = useState<FeedItem>()
    const [ selectedItemIndex, setSelectedItemInex ] = useState<number>(0)

    return {
        context: { item: selectedItem, selectedIndex: selectedItemIndex },
        set: (i, idx) => { setSelectedItem(i); setSelectedItemInex(idx) },
        open: (i, idx) => { setSelectedItem(i); setSelectedItemInex(idx); showTheater.open() },
        toggle: showTheater
    }

}