import { useQuery } from "@tanstack/react-query";
import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-grid-system";
import { BsChevronDown, BsList } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ArtistList } from "../components/ArtistList";
import { ArtistPlanet } from "../components/ArtistPlanet";
import { ArtistPreviewCard } from "../components/ArtistPreviewCard";
import { Bump } from "../components/Bump";
import { SkCard } from "../components/Card";
import { Feed } from "../components/Feed";
import { Flex } from "../components/Flex";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { MobileSearchArea } from "../components/Search/MobileSearchArea";
import { ShortArtistCell } from "../components/ShortArtistCell";
import { SkButton, SkCircleButton } from "../components/SkButton";
import { SkImageTheater } from "../components/SkImageTheater";
import { SkTabs } from "../components/SkTabs";
import { SkText } from "../components/SkText";
import { TagPicker } from "../components/TagPicker";
import { Colors } from "../constants/Colors";
import { Tags } from "../constants/RelevantTags";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { QueryKeys } from "../data/QueryKeys";
import { SearchArtists } from "../data/actions/ArtistActions";
import { GetUndefined } from "../data/actions/ControlActions";
import { SearchAction } from "../data/actions/SearchActions";
import { ArtistProfile, ArtistSearchResult } from "../data/models/Artist";
import { ArtistSearchParams } from "../data/requests/ArtistSearchParams";
import { useSelectedFeedItem } from "../hooks/useSelectedFeedItem";
import { useWindowSize } from "../hooks/useWindowSize";
import { QueryString } from "../utils/QueryString";

type statusOption = 'all' | 'open' | 'opening-soon'

export const BrowseScreen = React.memo(() => {

    const { q } = QueryString.useParams();
    const results = useQuery(q ? QueryKeys.Search(q) : QueryKeys.Nothing(), q ? () => SearchAction(q) : GetUndefined)
    const nav = useNavigate()
    const anyResults = results?.data?.data?.length
    const [ anyQuery, setAnyQuery ] = useState(false)
    const { isMobile } = useWindowSize()

    return (
        <ScreenWrapper noPadding={ isMobile }>
            {
                isMobile ? (
                    <MobileSearchArea onDisplayingResults={ setAnyQuery } />
                ) : null
            }
            {/* TODO -- We need to make a separate /browse and /search screen at this point */}
            {
                isMobile ? (
                    anyQuery ? null : <BrowseScreenContents />
                ) : (
                    anyResults ? <SearchResults results={ results?.data?.data } /> : <BrowseScreenContents />
                )
            }
        </ScreenWrapper>
    )

})

const BrowseScreenContents = React.memo(() => {

    const nav = useNavigate()

    const p = QueryString.useParams<{ tags: string, status: string, view: 'feed' | 'all-artists' }>()
    const [ tags, statusList ] = useMemo(() => [ p.tags?.split(',').filter(x => !!x) || [], p.status ? [ p.status ] : ['all'] ], [ p ])
    const { isMobile } = useWindowSize()

    const tagInference = useMemo(() => Tags.InferSearchTags(tags), [ tags ])

    const handleSelectTags = (newTags: string[]) => { nav(SkRoutes.Browse({ tags: newTags, status: statusList, view: p.view })) }
    const handleSelectStatus = (newStatus: string) => { nav(SkRoutes.Browse({ tags: tags, status: newStatus ? [ newStatus ] : [], view: p.view })) }
    const handleSwapView = (targetView: string) => { nav(SkRoutes.Browse({ tags, status: statusList, view: targetView })) }

    return (
        <Flex align='stretch'>

            <TagPicker selected={ tags } onSelect={ handleSelectTags } scroll style={{ maxWidth: '100%' }} />

            {/* Open / Opening Soon Section */}
            <SkTabs options={[
                { label: 'Explore', value: 'all' },
                { label: 'Open', value: 'open' },
                { label: 'Opening Soon', value: 'opening-soon' }
            ]} value={ statusList?.[0] || undefined } onSelect={ handleSelectStatus } style={{ paddingLeft: isMobile ? UiConstants.screenInset : 5, marginTop: 30, marginBottom: 10 }} />

            { p.view === 'all-artists'
                ? <AllArtistsBrowseDisplay status={ statusList?.[0] as any } tags={ tagInference.tags } onBack={ () => handleSwapView('feed') } />
                : <FeedBrowseDisplay status={ statusList?.[0] as any } tags={ tagInference.tags } onViewAll={ () => handleSwapView('all-artists') } />
            }

        </Flex>
    )

})

const FeedBrowseDisplay = React.memo((props: { status: statusOption, tags?: string[], onViewAll: () => void }) => {

    const { status, tags, onViewAll } = props
    const artistParams = { statuses: [ status ], tags }
    const openArtists = useQuery(QueryKeys.Artist.Search(artistParams), () => SearchArtists(artistParams))

    const feedItem = useSelectedFeedItem()

    return (
        <Flex style={{ maxWidth: '100%' }}>

            <ArtistCarouselGrid artists={ openArtists?.data?.data || [] } onViewAll={ onViewAll } searchContext={ artistParams } />

            <Flex align='center' style={{ width: '100%', boxSizing: 'border-box', marginTop: 30 }}>
                <Feed preset={ status as any } searchParams={{ tags }} onItemMediaClick={ feedItem.open } />
            </Flex>

            <SkImageTheater images={ feedItem.context?.item?.media || [] } toggle={ feedItem.toggle } start={ feedItem.context?.selectedIndex } />
        </Flex>
    )

})

export const ArtistCarouselGrid = React.memo((props: { artists: ArtistSearchResult[], searchContext?: ArtistSearchParams, onViewAll: () => void }) => {

    const { artists, searchContext, onViewAll } = props
    const { isMobile } = useWindowSize()
    const [ expanded, setExpanded ] = useState(false)
    const len = artists?.length
    const sliceSize = expanded ? 16 : 8
    const slice = artists?.slice(0, sliceSize)
    const tail = artists?.slice(sliceSize, sliceSize + 5)
    const theresMore = len > sliceSize

    if (isMobile) return (
        <Flex row align='stretch' style={{ overflowX: 'scroll', maxWidth: '100%', margin: '20px 0' }}>
            <Bump w={UiConstants.screenInset} />
            {
                slice?.map(a => (
                    <ArtistPreviewCard artist={ a } navOnClick size='fixed-width' showTimeSinceOpen searchContext={ searchContext } />
                ))
            }
            {
                theresMore ? (
                    <SkCard onClick={ onViewAll }>
                        <Flex align='center' justify='center' style={{ height: '100%', width: 200 }}>
                            <SkText variant='c1' style={{ marginBottom: UiConstants.standardSpacing }}>Browse All</SkText>
                            <Flex row>
                                { tail.map(a => <ArtistPlanet noMoon artist={ a } size={ 40 } style={{ marginLeft: -10, borderStyle: 'solid', borderWidth: 4, borderColor: Colors.DarkerBackground }} />) }
                            </Flex>
                        </Flex>
                    </SkCard>
                ) : null
            }
            <Bump w={ 100 } />
        </Flex>
    )

    return (
        <Flex align='stretch' style={{ width: '100%' }}>
            <Container fluid style={{ width: '100%' }}>
                <Row>
                    { slice.map(a => (
                        <Col lg={ 3 } md={ 4 } style={{ marginTop: UiConstants.tightSpacing }}>
                            <ArtistPreviewCard artist={ a } navOnClick size='normal' showTimeSinceOpen searchContext={ searchContext } />
                        </Col>
                    )) }
                </Row>
            </Container>
            { theresMore ? (
                <Flex row justify='center' style={{ marginTop: UiConstants.standardSpacing }}>
                    <SkCircleButton onClick={ expanded ? onViewAll : () => setExpanded(true) }>
                        { expanded ? <BsList size={ UiConstants.iconButtonSize } color='white' /> : <BsChevronDown size={ UiConstants.iconButtonSize } color='white' /> }
                    </SkCircleButton>
                </Flex>
            ) : null }
        </Flex>
    )

})

const AllArtistsBrowseDisplay = React.memo((props: { status: statusOption, tags?: string[], onBack: () => void }) => {

    const { status, tags, onBack } = props
    const artistParams: ArtistSearchParams = useMemo(() => ({ statuses: [ status ], tags }), [ status, tags ])
    const headSize = status === 'all' ? 0 : 8

    return (
        <Flex style={{ maxWidth: '100%', minWidth: '100%', padding: UiConstants.tightSpacing, boxSizing: 'border-box' }}>

            <div style={{ width: '100%', marginBottom: UiConstants.standardSpacing, boxSizing: 'border-box' }}>
                <SkButton variant='secondary-invert' title='Back' onClick={ onBack } />
            </div>

            <ArtistList searchParams={ artistParams } gallerySize={ headSize } button='followed' />

        </Flex>
    )
})

const SearchResults = React.memo((props: { results: ArtistProfile[] }) => {

    const { results } = props

    if (!results?.length) return null

    return (
        <Flex align='stretch' style={{ flex: 1, padding: UiConstants.screenInset, paddingTop: 0 }}>
            {
                results.map(r => (
                    <ShortArtistCell artist={ r } button='followed' />
                ))
            }
        </Flex>
    )

})
