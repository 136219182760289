import { SearchClient } from "algoliasearch";
import { create } from "zustand";

export type ConfigStore = {
    algolia: {
        searchClient: SearchClient | null,
        indexName: string
    }
}

type ConfigStoreMethods = {
    setConfig: (configPartial: Partial<ConfigStore>) => void
}

export const useConfig = create<ConfigStore & ConfigStoreMethods>((set, get) => {

    return {

        algolia: {
            searchClient: null,
            indexName: 'default'
        },

        setConfig: (configPartial) => set(configPartial)
    }

})