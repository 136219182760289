import React, { useCallback, useEffect, useRef } from "react";
import { Configure, InstantSearch, useSearchBox } from "react-instantsearch-core";
import { useNavigate } from "react-router-dom";
import { SkRoutes } from "../../constants/Routes";
import { useConfig } from "../../stores/ConfigStore";
import { SkTextInput } from "../SkTextInput";
import { SearchHitContainer } from "./SearchHitContainer";
import { useHeaderSearchUiContext } from "./useSearchUiContext";
import { ConfigureSearchForTypes } from "./ConfigureSearchForTypes";
import { useBrowseScreenSearchContext } from "../../screens/NewBrowseScreen/useBrowseScreenSearchContext";

export const DesktopSearchBox = React.memo(() => {

    const { searchClient, indexName } = useConfig(c => c.algolia)

    return (
        <InstantSearch searchClient={ searchClient } indexName={ indexName }>
            <ConfigureSearchForTypes types={['artist', 'tag']} />
            <SearchBoxInput />
            <SearchHitContainer floating />
        </InstantSearch>
    )

})

const SearchBoxInput = React.memo(() => {
    const { refine } = useSearchBox()
    const { resultsVisible, showResults, hideResults, setSelectedIndex, selectedIndex, selectedDirective, query, setQuery } = useHeaderSearchUiContext()
    const nav = useNavigate()

    const inputRef = useRef<HTMLInputElement>()

    useEffect(() => showResults(), [query])

    const handleKey = useCallback((k: string) => {

        if (k === 'Enter') {
            hideResults()
            inputRef?.current?.blur()
            if (selectedIndex >= 0 && !!selectedDirective) {
                switch (selectedDirective.type) {
                    case 'artist': nav(SkRoutes.Artist.Show(selectedDirective.object?.handle)); break;
                    case 'tag': nav(SkRoutes.Browse({ tags: [selectedDirective.object?.name] })); break;
                    case 'search':
                    default: nav(SkRoutes.Browse({ q: query })); break;
                }
            } else {
                nav(SkRoutes.Browse({ q: query }))
            }
        }

        if (k === 'Escape') {
            inputRef?.current?.blur()
            hideResults()
        }

        if (k === 'ArrowUp') setSelectedIndex(selectedIndex - 1)
        if (k === 'ArrowDown') {
            if (!resultsVisible) {
                showResults()
                setSelectedIndex(0)
            } else setSelectedIndex(selectedIndex + 1)
        }
    }, [resultsVisible, nav, showResults, hideResults, setSelectedIndex, selectedIndex, selectedDirective, query])

    const handleChange = useCallback((newQuery: string) => {
        setQuery(newQuery)
        refine(newQuery)
    }, [ refine, setQuery ])

    return (
        <SkTextInput
            ref={ inputRef }
            showReset
            onReset={ () => useBrowseScreenSearchContext.getState().clearQueryFromRoute(nav) }
            value={ query }
            onTextChange={ handleChange }
            onKey={ handleKey }
            onFocus={ showResults }
            onClick={ showResults }
            placeholder="Search for artists, tags, and more..."
        />
    )
})
