import React, { useCallback, useEffect, useMemo, useState } from "react"
import { CgHome, CgList } from "react-icons/cg"
import { GoTelescope } from "react-icons/go"
import { MdSearch } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"
import { Colors } from "../constants/Colors"
import { HtmlIds } from "../constants/HtmlIds"
import { SkRoutes } from "../constants/Routes"
import { UiConstants } from "../constants/UiConstants"
import { useAuth } from "../hooks/useAuth"
import { useNavRoute } from "../hooks/useNavRoute"
import { useWindowSize } from "../hooks/useWindowSize"
import { Flex } from "./Flex"
import { UserImage } from "./UserImage"
import { TbTelescope, TbHome } from 'react-icons/tb'

import "./Footer.scss";

let lastScrollY = window.scrollY;

type NavRoute = 'home' | 'search' | 'following' | 'other';
export const Footer = React.memo(() => {

    const { isMobile } = useWindowSize()
    const nav = useNavigate()
    const { loggedIn } = useAuth()

    const eSize = isMobile ? UiConstants.headFootHeights.mobile.footer : UiConstants.headFootHeights.desktop.footer

    const route = useNavRoute()
    const iconColor = (r: NavRoute) => r === route ? Colors.PrimarierBlue : 'white'

    const handlePress = (goToRoute: string, comparisonRoute: NavRoute) => {        
        if(route === comparisonRoute) window.scrollTo({ top: 0, behavior: 'smooth' });
        else nav(goToRoute)
    }

    // const handleScroll = (e: Event) => {
    //     if(window.scrollY > lastScrollY) {
    //         setIsScrolled(true);
    //     }
    //     else {
    //         setIsScrolled(false);
    //     }
    //     lastScrollY = window.scrollY;
    // };

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    // }, []);

    if (!loggedIn) 
        return null;

    return (
        <nav className="nav-footer">
            <button className="nav-button" onClick={() => handlePress(SkRoutes.Home(), 'home')}>
                <TbHome color={ iconColor('home') } size={ 27 } />
            </button>
            <button className="nav-button" onClick={() => handlePress(SkRoutes.Search(''), 'search')}>
                <TbTelescope color={ iconColor('search') } size={ 27 } />
            </button>
            <button className="nav-button" onClick={() => handlePress(SkRoutes.Following(), 'following')}>
                <CgList color={ iconColor('following') } size={ 27 } />
            </button>

            <button className="nav-button">
                <UserImage navOnClick size={ 35 } />
            </button>
            {/* <div style={{ display: 'flex', flex: 1, justifyContent: 'center', padding: '10px' }}>
                <UserImage navOnClick size={ 35 } />
            </div> */}
        </nav>
    );

})
