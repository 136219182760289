import React, { useMemo } from "react"
import { create } from "zustand"
import { SkBackdrop } from "./SkModal"
import { Flex } from "./Flex"
import { SkCircleButton } from "./SkButton"
import { BsArrowLeft, BsArrowRight, BsX } from "react-icons/bs"
import { Colors } from "../constants/Colors"
import { useWindowSize } from "../hooks/useWindowSize"

export type SkImageTheaterContext = {

    isOpen: boolean
    images: string[]
    visibleIndex: number

    open: (images: string[], idx?: number) => void
    close: () => void
    next: () => void
    prev: () => void
    getNImages: () => number
    getCurrentImageUrl: () => string

}

export const useSkImageTheater = create<SkImageTheaterContext>((set, get) => {

    return {

        isOpen: false,
        images: [],
        visibleIndex: 0,

        open: (images, idx) => {
            if (idx !== undefined) set({ visibleIndex: idx })
            set({ images, isOpen: true })
        },

        close: () => {
            set({ images: [], isOpen: false, visibleIndex: 0 })
        },

        next: () => {
            const nextIdx = get().visibleIndex + 1
            const maxIdx = get().getNImages() - 1
            if (nextIdx > maxIdx) return;
            set({ visibleIndex: nextIdx })
        },

        prev: () => {
            let nextIdx = get().visibleIndex - 1
            if (nextIdx < 0) return;
            set({ visibleIndex: nextIdx })
        },

        getNImages: () => get().images?.length || 1,

        getCurrentImageUrl: () => get().images?.[get().visibleIndex] || ''

    }

})

export const SkImageTheater2 = React.memo(() => {

    const theater = useSkImageTheater()

    return (
        <SkBackdrop open={ theater.isOpen } onClick={ theater.close }>
            <Flex style={{ width: '100%', height: '100%' }}>
                <SkCircleButton htmlId='image-theater-close' variant='secondary' onClick={ theater.close } style={{ position: 'absolute', right: 40, top: 40, zIndex: 10 }}>
                    <BsX color={ Colors.DarkBackground } size={ 30 } />
                </SkCircleButton>
                <Flex row justify='center' align='center' style={{
                    width: '100%', height: '100%'
                }}>
                    <FullHeightMove direction='backward' onClick={ theater.prev } />
                    <div style={{
                        flex: 1,
                        backgroundImage: `url(${ theater.getCurrentImageUrl() })`, height: '100%', maxWidth: 1024, backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat', backgroundPosition: 'center'
                        }} onClick={ e => e.stopPropagation() }>
                    </div>
                    <FullHeightMove direction='forward' onClick={ theater.next } />
                </Flex>
            </Flex>
        </SkBackdrop>
    )

})

const FullHeightMove = React.memo((props: { direction: 'forward' | 'backward', disabled?: boolean, onClick: () => void }) => {

    const { isMobile } = useWindowSize()
    const { direction, disabled, onClick } = props

    const { icon, posProp } = useMemo(() => {
        switch(direction) {
            case 'backward': return { icon: <BsArrowLeft color='white' size={ 30 } />, posProp: 'left' }
            case 'forward': return { icon: <BsArrowRight color='white' size={ 30 } />, posProp: 'right' }
        }
    }, [ direction ])

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        if (!disabled) onClick?.()
    }

    return (
        <Flex htmlId={`image-theater-${ direction }`} justify='center' align='center' style={{
            zIndex: 4,
            height: '100%',
            minWidth: isMobile ? '50vw' : '13vw',
            maxWidth: 200,
            position: 'absolute',
            [posProp]: 0,
            opacity: isMobile ? 0 : 1,
            backgroundColor: 'rgba(0,0,0,0.4)'
        }} onClick={ handleClick }>
            { disabled ? null : icon }
        </Flex>
    )

})