import { useQueries, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { GoNote } from "react-icons/go";
import { BsGlobe, BsTwitter } from "react-icons/bs";
import { FaCloud, FaTag } from "react-icons/fa";
import { BiRename } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { ArtistPlanet } from "../components/ArtistPlanet";
import { Flex } from "../components/Flex";
import { ScreenWrapper } from "../components/ScreenWrapper";
import { SkButton, SkCircleButton } from "../components/SkButton";
import { SkText } from "../components/SkText";
import { SkTextInput } from "../components/SkTextInput";
import { SkRoutes } from "../constants/Routes";
import { UiConstants } from "../constants/UiConstants";
import { CreateArtistAction, DeleteArtistAction, GetArtistById, UpdateArtistAction } from "../data/actions/ArtistActions";
import { ArtistProfile } from "../data/models/Artist";
import { UpdateArtistRequest } from "../data/requests/UpdateArtistRequest";
import { useWindowSize } from "../hooks/useWindowSize";
import { TagEditor } from "../components/TagEditor";
import { Colors } from "../constants/Colors";

export const EditArtistScreen = React.memo(() => {

    const { artistId } = useParams<{ artistId: string }>();
    const { isMobile } = useWindowSize()

    const [ update, setUpdate ] = useState<Partial<UpdateArtistRequest>>({})
    const [ newTag, setNewTag ] = useState<string>()
    const [ saving, setSaving ] = useState(false)

    const nav = useNavigate();

    const existing = useQuery([`get-artist-${artistId}`], () => GetArtistById(artistId))
    useEffect(() => {
        const d = existing?.data?.data
        if (!d) return;
        setUpdate({
            artistId: d._id,
            artistName: d.name,
            commissionInfoWebpage: d.commissionInfoUrl,
            commissionStatusNote: d.commissionStatusNote,
            tags: d.tags,
            twitterHandler: d.twitter
        })
    }, [ existing?.data?.data?._id ])

    const updateField = (field: keyof UpdateArtistRequest, value: any) => setUpdate({ ...update, [field]: value })

    const setTags = (tags: string[]) => setUpdate({ ...update, tags: tags })
    const addTag = (tag: string) => setUpdate({ ...update, tags: [ ...(update.tags || []).filter(t => t !== tag), tag ] })
    //const removeTag = (tag: string) => setUpdate({ ...update, tags: [ ...(update.tags || []).filter(t => t !== tag) ] })

    const handleSave = async () => {
        const effectiveUpdate = !!newTag ? { ...update, tags: [ ...(update.tags || []).filter(t => t !== newTag), newTag ] } : update
        setSaving(true)
        const result = await UpdateArtistAction(effectiveUpdate)
        setSaving(false)
        if (result.status < 400) nav(SkRoutes.Artist.Show(artistId))
    }

    const handleDelete = async () => {
        if(!window.confirm('Are you sure you want to delete this artist?')) return;
        const result = await DeleteArtistAction(artistId)
        if (result.status < 400) nav(SkRoutes.Profile())
        else alert()
    }


    return (
        <ScreenWrapper>
            <SkText variant='headline'>Edit Artist</SkText>

            <Flex row={ !isMobile }>

                <Flex row justify='center' style={{ margin: isMobile ? '40px 0' : '20px 20px 0 0' }}>
                    <ArtistPlanet artist={ existing?.data?.data } size={ isMobile ? 250 : 200 } />
                </Flex>

                <Flex style={{ flex: 1 }}>
                    <CirlceIconInputField icon={ <BiRename size={ UiConstants.iconButtonSize } color='white' /> } value={ update.artistName } onTextChange={ v => updateField('artistName', v) } />
                    <CirlceIconInputField icon={ <BsTwitter size={ UiConstants.iconButtonSize } color='white' /> } value={ update.twitterHandler } onTextChange={ v => updateField('twitterHandler', v) } />
                    <CirlceIconInputField icon={ <FaCloud size={ UiConstants.iconButtonSize } color='white' /> } value={ update.bskyHandle } onTextChange={ v => updateField('bskyHandle', v) } />
                    <CirlceIconInputField icon={ <BsGlobe size={ UiConstants.iconButtonSize } color='white' /> } value={ update.commissionInfoWebpage } onTextChange={ v => updateField('commissionInfoWebpage', v) }>
                        <SkButton title='View Site' variant='secondary' href={ update.commissionInfoWebpage } disabled={ !update.commissionInfoWebpage } style={{ marginTop: 20 }} />
                    </CirlceIconInputField>
                    <CirlceIconInputField icon={ <GoNote size={ UiConstants.iconButtonSize } color='white' /> } value={ update.commissionStatusNote } onTextChange={ v => updateField('commissionStatusNote', v) } />
                    <CirlceIconInputField icon={ <FaTag size={ UiConstants.iconButtonSize } color='white' /> } value={ newTag } onTextChange={ setNewTag } onSubmit={ v => { addTag(v); setNewTag(''); } } />
                    <TagEditor tags={ update.tags } onUpdate={ setTags } />
                </Flex>


            </Flex>

            <SkButton title='Save' style={{ marginTop: 120 }} onClick={ handleSave } disabled={ saving } />
            <SkButton title='Delete ⚠' style={{ marginTop: 20, marginBottom: 80, backgroundColor: Colors.FailureRed }} onClick={ handleDelete } disabled={ saving } />
        </ScreenWrapper>
    )

})

export const CreateArtistScreen = React.memo(() => {

    const [ bskyHandle, setBskyHandle ] = useState('')
    const [ saving, setSaving ] = useState(false)
    const nav = useNavigate()

    const handleSubmit = async () => {
        setSaving(true)
        const artist = await CreateArtistAction(bskyHandle)
        setSaving(false)
        if (artist.data?.handle) nav(SkRoutes.Artist.Edit(artist.data?.handle))
        else alert('There was a problem creating the artist')
    }

    return (
        <ScreenWrapper>
            <SkText variant='headline'>Add Artist</SkText>

            <Flex row style={{ marginTop: 80 }}>
                <CirlceIconInputField
                    icon={ <FaCloud size={ UiConstants.iconButtonSize } color='white' /> }
                    onSubmit={ handleSubmit } value={ bskyHandle } onTextChange={ setBskyHandle }>
                    <SkButton variant='secondary' title='Continue' onClick={ handleSubmit } style={{ marginTop: 20 }} />
                </CirlceIconInputField>
            </Flex>

        </ScreenWrapper>
    )


})

const CirlceIconInputField = React.memo((props: { icon: JSX.Element, value: string, onTextChange: (value: string) => void, onSubmit?: (value: string) => void, children?: any }) => {

    const { icon, value, onTextChange, onSubmit, children } = props

    return (
        <Flex row style={{ marginBottom: 20 }}>
            <SkCircleButton style={{ marginRight: 20 }}>{ icon }</SkCircleButton>
            <div>
                <SkTextInput value={ value } onTextChange={ onTextChange } onSubmit={ onSubmit } />
                { children }
            </div>
        </Flex>
    )
})