import React from "react";
import { Colors } from "../constants/Colors";
import { UiConstants } from "../constants/UiConstants";
import { useWindowSize } from "../hooks/useWindowSize";
import { Bump } from "./Bump";
import { SkText, SkTextVariant } from "./SkText";

export type SkButtonVariant = 'primary' | 'secondary' | 'secondary-invert' | 'text'

interface SkButtonProps {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
    htmlId?: string
    href?: string
    style?: React.CSSProperties
    title?: string
    variant?: SkButtonVariant
    disabled?: boolean
    icon?: any
    children?: any
}

export const SkButton = React.memo((props: SkButtonProps) => {

    const { className, onClick, style, title, children, variant = 'primary', href, disabled, icon, htmlId } = props
    const { scale } = useWindowSize()

    const colors: Record<SkButtonVariant, { bg: string, fg: string }> = {
        primary: { bg: Colors.PrimaryBlue, fg: 'white' },
        secondary: { bg: '#d9d9d9', fg: Colors.DarkerBackground },
        text: { bg: Colors.DarkBackground, fg: 'white' },
        'secondary-invert': { bg: Colors.DarkerBackground, fg: 'white' }
    }

    const handleClick = (e: any) => {
        e.stopPropagation()
        if (!disabled) onClick?.(e)
    }

    return (
        <a style={{
            backgroundColor: colors[variant].bg,
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: UiConstants.inputHeight * scale,
            boxSizing: 'border-box',
            borderRadius: UiConstants.borderRadius * scale,
            textDecoration: 'none',
            cursor: 'pointer',
            ...style
        }} className={className} onClick={ handleClick } id={ htmlId } href={ disabled ? undefined : href } target='_blank'>
            { icon ? (
                <>
                    { icon }
                    <Bump w={ 10 } />
                </>
            ) : null }
            { title ? (
                <SkText variant='control-label' style={{ color: colors[variant].fg, textAlign: 'center', fontWeight: '500' }}>{ title }</SkText>
            ) : children }
        </a>
    )

})

export const SkCircleButton = React.memo((props: SkButtonProps & { size?: number }) => {

    const { scale } = useWindowSize()
    const size = (props.size || (UiConstants.borderRadius * 2)) * scale

    return (
        <SkButton { ...{ variant: 'secondary-invert', ...props } } style={{ width: size, minWidth: size, height: size, minHeight: size, ...props.style }}>{ props.children }</SkButton>
    )

})