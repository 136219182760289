import React, { useEffect, useMemo, useRef } from "react";
import { Colors } from "../constants/Colors";
import { InitializeTags, RelevantTags, Tags } from "../constants/RelevantTags";
import { UiConstants } from "../constants/UiConstants";
import { Bump } from "./Bump";
import { Flex } from "./Flex";
import { SkPill } from "./SkPill";
import { SkTextVariant } from "./SkText";
import { useWindowSize } from "../hooks/useWindowSize";
import { useDragScroll } from "../hooks/useDragScroll";

interface TagPickerProps {
    selected?: string[]
    onSelect?: (selected: string[]) => void
    wrap?: boolean
    scroll?: boolean
    style?: React.CSSProperties
    textVariant?: SkTextVariant
}

export const TagPicker = React.memo((props: TagPickerProps) => {

    const { selected, onSelect, wrap, scroll, style, textVariant } = props
    const containerRef = useRef(null)
    const { isMobile } = useWindowSize()

    // Need this ID for scrolling to work
    const htmlId = useMemo(() => `tag-picker${Math.floor(Math.random() * 99999)}`, [])
    const selectedSet = useMemo(() => new Set(selected), [selected])

    // In case the startup init didn't work, init the tag here too
    useEffect(() => { InitializeTags() }, [])

    const tags = useMemo(() => {
        return Tags.Refine(selected)
    }, [ selected ])

    const { hasSwiped } = useDragScroll({ on: !isMobile, sliderRef: containerRef, momentumVelocity: 0.9 }, [ tags ])

    const handleSelect = (tag: string) => {

        if (hasSwiped) return; // If they just swiped, it wasn't a click

        let newTags = selected;
        let removing = false

        removing = selected?.includes(tag)
        newTags = removing ? selected?.filter(x => x !== tag) || [] : [ ...selected, tag ]

        onSelect?.(newTags)
        if(!removing && scroll) document.querySelector(`#${htmlId}`)?.scroll({ left: 0 })

    }

    return (
        <div ref={ containerRef } id={ htmlId }
            style={{ display: 'flex', flexDirection: 'row', flexWrap: wrap ? 'wrap' : undefined, overflowX: scroll ? 'scroll' : undefined, ...style }}>
            <Bump w={ scroll && isMobile ? UiConstants.screenInset : 0 } />
            {tags.map(t => (
                <SkPill unselectedColor={ Colors.MidDarkBackground } label={t} onClick={ () => handleSelect(t) } selected={ selectedSet.has(t) } textVariant={ textVariant } />
            ))}
            <Bump w={ scroll ? 100 : 0 } />
        </div>
    )

})