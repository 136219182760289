import Axios, { AxiosError } from 'axios';
import { SkResponse } from '../models/SkResponse';
import { Token } from '../models/Token';
import { MakeUrl } from './Urls';

export const GetTokenAction = async (): Promise<SkResponse<Token>> => {
    try {
        // Fun race condition quirk! :)
        // This request _sometimes_ runs before Startup.ts has a chance to install
        // the `withCredentials` Axios interceptor. To exterminate this irksome problem
        // I've just hardcoded the `withCredentials` option here.
        return await Axios.get<Token>(MakeUrl('/auth/token'), { withCredentials: true })
    } catch (e) {
        const ae = e as AxiosError
        return ae.response as SkResponse<Token>
    }
}